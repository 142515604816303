
$vertical-tabs-header-width: 200px;
$vertical-tabs-content-width: 800px;

// ======================== Vertical tabs - TEST START ======================

.tab-content-title {
  display: flex;
  align-items: center;
  margin: -24px -24px 24px;
  padding: 1.25rem;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid $border-color;
}


// Use this somehow!!!
/*:not(.mat-expansion-panel) & {
  border: 1px solid red;
}*/

.vertical-tabs.mat-tab-group  {

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin: auto;
  }

  // Immediate used to avoid layout bug when having another Tabs element
  > .mat-tab-header {

    @include media-breakpoint-up(lg) {
      background: transparent;
      box-shadow: none;
      border: none;
      margin: 0;
      width: $vertical-tabs-header-width;
      margin-left: 20px;
      border-radius: 0;
      padding-right: 12px;
    }

    .mat-tab-header-pagination {

      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }

    mat-ink-bar.mat-ink-bar {

      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }

    .mat-tab-labels {

      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }
    }

    .mat-tab-label {

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }

    .mat-tab-label-active {
      opacity: 1;
      color: #6200ea;
    }

    .mat-tab-label:hover {
      opacity: 1;
    }

    label.mat-radio-label {
      margin: 0;
    }
  }

  > .mat-tab-body-wrapper {
    /*max-height: calc(100vh - 190px);
    @include scrollbars($scrollbar-width, $scrollbar-fg, $scrollbar-bg);*/

    @include media-breakpoint-up(lg) {
      flex: 1 1 100%;
      max-width: 100%;
      align-self: flex-start;
    }

    @include media-breakpoint-up(xxl) {
      flex: 0 0 $vertical-tabs-content-width;
      max-width: $vertical-tabs-content-width;
    }

    @include media-breakpoint-up(uhd-up) {
      flex: 0 0 840px;
      max-width: 840px;
    }

    > .mat-tab-body {

      .mat-tab-body-content {
        padding: 1.5rem;

        .mat-card .mat-card-title {
          font-size: 16px;
          font-weight: 500;
          margin-left: 0.5rem;
        }

        .mat-action-row {
          margin: 0 -1.5rem -1.5rem;
          padding: 1rem;

          /* Remove material styling */
          button.mat-button-base {
            margin-left: 0;
          }
        }
      }
    }
  }

  // Special view with extra content
  &.placement-dp-tab {

    .mat-tab-body-wrapper {

      // min-width: 1500px;

      @include media-breakpoint-up(lg) {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
  }
}

.vertical-tabs-action-row {
  margin-top: 2rem;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin-left: $vertical-tabs-header-width + 20px;
  }

  @include media-breakpoint-up(xxl) {
    width: $vertical-tabs-content-width;
  }

  @include media-breakpoint-up(uhd-up) {
    width: 840px;
  }
}

// ======================== TEST END ======================

.mat-center-tabs {

  .mat-tab-labels {
    justify-content: center;
  }
}

.mat-center-links {

  .mat-tab-links {
    text-align: center;
    justify-content: center;
  }
}

.mat-tab-header,
.mat-tab-body-wrapper {
  background: white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &.reset-styling,
  .reset-styling & {
    margin-bottom: 0;
    background: transparent;
    border-radius: unset;
    box-shadow: unset;
  }
}

.mat-tab-header {
  margin-bottom: 0;

  .mat-tab-header-pagination-before {
    box-shadow: none;
    border-right: 1px solid $border-color;
  }

  .mat-tab-header-pagination-after {
    box-shadow: none;
    border-left: 1px solid $border-color;
  }

  .mat-tab-header-pagination-chevron {
    color: $primary;
  }
}

.mat-tab-link:hover {
  text-decoration: none;
}

// ======================== Line item - Targeting - Supply - Segment special styling ======================



// ======================== Report builder tabs - Special case just for report builder ======================

.mat-tab-group--report-builder {

  .mat-tab-body-wrapper {
    background: transparent;
    box-shadow: none;
  }
}

// ======================== Card header tabs ======================

.mat-tab-group {

  .mat-card-header & {
    // Removed ONLY because of line-items-table responsive fix
    // If noticed this was used in more then 1 place, find a fix on that table
    // and uncomment this
    // margin: 0 0 0 -1rem;
  }
}

.mat-tab-label {

  .mat-card-header & {
    height: 63px;
  }
}

// ======================== Campaigns - Campaign details - special styling ======================

.campaign-details-content {

  .mat-tab-body-wrapper {
    background: transparent;
    box-shadow: none;
  }
}
