@import 'abstract/variables';

mt-status-box:not(:last-of-type) {
  margin-right: .5rem;
}

.status {

  &-plate-group {
    display: flex;
    align-items: center;
    //justify-content: center;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      //justify-content: flex-end;
    }
  }

  &-circle-group {
    display: flex;
    align-items: center;

    .status-circle {
      display: flex;
      align-items: center;
      width: 50px;

      .fa-circle {
        font-size: 14px;
        margin-right: .5rem;
      }

      + .status-circle {
        margin-left: 1rem;
      }
    }
  }

  &-plate {
    min-height: 40px;
    display: flex;
    // flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 6px 9px;
    background: $white;
    line-height: 1.125;
    border: none !important;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    transition: all .33s ease-in-out;

    &.status-plate-active {
      color: $white;
    }

    > small {
      font-size: inherit;
      font-weight: inherit;
      margin-right: .5rem;
      white-space: nowrap;
      text-transform: capitalize;
    }

    span {
      margin: 0;
      width: auto;
      display: inline-block;
      min-width: 20px;
      height: 20px;
      font-size: 12px;
      font-weight: 900;
      line-height: 20px;
      text-align: center;
      border-radius: 20px;
      color: white;
      padding: 0px 4px;
      background-color: $gray-lighter;
    }

    &--blue {
      border-color: $blue;
      color: $blue-light;
      
      small {
        color: $blue;
      }

      &:hover {
        background: rgba($blue-light, .125);
      }

      &.status-plate-active {
        background: $blue-light;
        span {
          background: $blue;
          color: $blue-light;
        }
      }

      &:not(.status-plate-active) {
        background: white;
        small {
          color: $blue !important;
        }
        span {
          background: $blue-light;
          color: $blue;
        }
      }
    }

    &--active,
    &--green {
      border-color: $active-text;
      small {
        color: $active-text;
      }

      &:hover {
        background: rgba($green, .125);
      }

      &.status-plate-active {
        background: $active-bg;

        span {
          background: $active-text;
          color: $active-bg;
        }
      }

      &:not(.status-plate-active) {
        background: white;
        small {
          color: $active-text !important;
        }
        span {
          background: $active-bg;
          color: $active-text;
        }
      }
    }

    &--inactive,
    &--red {
      border-color: $inactive-text;
      small {
        color: $inactive-text;
      }

      &:hover {
        background: rgba($red, .125);
      }

      &.status-plate-active {
        background: $inactive-bg;

        span {
          background: $inactive-text;
          color: $inactive-bg;
        }
      }

      &:not(.status-plate-active) {
        background: white;

        small {
          color: $inactive-text !important;
        }
        span {
          background: $inactive-bg;
          color: $inactive-text;
        }
      }
    }

    &--tealikegreen {
      border-color: $active-text-ai;
      small {
        color: $active-text-ai;
      }

      &:hover {
        background: rgba($green, .125);
      }

      &.status-plate-active {
        background: $active-bg-ai;

        span {
          background: $active-text-ai;
          color: $active-bg-ai;
        }
      }

      &:not(.status-plate-active) {
        background: white;
        small {
          color: $active-text-ai !important;
        }
        span {
          background: $active-bg-ai;
          color: $active-text-ai;
        }
      }
    }

    &--orange {
      border-color: $inactive-text-orange;
      small {
        color: $inactive-text-orange;
      }

      &:hover {
        background: rgba($orange, .125);
      }

      &.status-plate-active {
        background: $inactive-bg-orange;

        span {
          background: $inactive-text-orange;
          color: $inactive-bg-orange;
        }
      }

      &:not(.status-plate-active) {
        background: white;

        small {
          color: $inactive-text-orange !important;
        }
        span {
          background: $inactive-bg-orange;
          color: $inactive-text-orange;
        }
      }
    }

    // &--orange {
    //   color: $orange;

    //   &:hover {
    //     background: rgba($orange, .125);
    //   }

    //   &.status-plate-active {
    //     background: $orange;
    //   }
    // }

    &--archived,
    &--gray {
      border-color: $archived-text;

      small {
        color: $archived-text;
      }

      &:hover {
        background: rgba($bluegray, .125);
      }

      &.status-plate-active {
        background: $archived-bg;

        span {
          background: $archived-text;
          color: $archived-bg;
        }
      }

      &:not(.status-plate-active) {
        background: white;
        small {
          color: $archived-text !important;
        }
        span {
          background: $archived-bg;
          color: $archived-text;
        }
      }

      &.status-plate--total {
        &:hover {
          cursor: default;
          background: $white;
        }
      }
    }

    &--lightgray {
      color: $gray-lighter;

      &:hover {
        background: rgba($gray-lighter, .125);
      }

      &.status-plate-active {
        background: $gray-lighter;
      }
    }

    &--ds-goal-met {
      color: $status-color-goal-met;

      &:hover {
        background: rgba($status-color-goal-met, .125);
      }

      &.status-plate-active {
        background-color: $status-color-goal-met;
      }
    }

    &--ds-completed {
      color: $status-color-completed;

      &:hover {
        background: rgba($status-color-completed, .125);
      }

      &.status-plate-active {
        background: $status-color-completed;
      }
    }

    &--ds-ready {
      color: $status-color-ready;

      &:hover {
        background: rgba($status-color-ready, .125);
      }

      &.status-plate-active {
        background-color: $status-color-ready;
      }
    }

    &--ds-delivering {
      color: $status-color-delivering;

      &:hover {
        background: rgba($status-color-delivering, .125);
      }

      &.status-plate-active {
        background-color: $status-color-delivering;
      }
    }

  }

  &-text {
    line-height: 1;
  }

  &-circle {

    .mat-cell & {
      font-size: 12px;
      margin-right: 0.5rem;
    }

    &--small {
       i {
        font-size: 8px !important;
      }
    }

    &--blue {
      color: $blue-light;
    }
    &--green {
      color: $green;
    }
    &--red {
      color: $red;
    }
    &--orange {
      color: $orange;
    }
    &--lightgray {
      color: $gray-lighter;
    }
    &--gray {
      color: $bluegray;
    }

    &--ds-goal-met{
      color: $status-color-goal-met;
    }

    &--ds-completed{
      color: $status-color-completed;
    }

    &--ds-ready{
      color: $status-color-ready;
    }

    &--ds-delivering{
      color: $status-color-delivering;
    }

    /*+ span {
      margin-left: 6px;
      //font-size: 14px;
    }*/
  }
}


.status-pill {
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 10px;
  white-space: nowrap;

  &-wrapper {

    &:not(:empty) {
      margin: 0 4px 4px 0;
    }
  }

  &--ds-goal-met {
    color: darken($status-color-goal-met, 22.5%);
    background-color: rgba($status-color-goal-met, 0.2);
  }

  &--ds-completed {
    color: $status-color-completed;
    background: rgba($status-color-completed, 0.1);
  }

  &--ds-ready {
    color: $status-color-ready;
    background-color: rgba($status-color-ready, 0.1);
  }

  &--ds-delivering {
    color: $status-color-delivering;
    background-color: rgba($status-color-delivering, 0.1);
  }

}
