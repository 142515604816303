@import "../abstract/variables";

body {

  .mat-menu-panel {
    max-width: 360px;
    min-height: auto !important;
    border-radius: 6px !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;

    &.data-list-menu {
      max-width: initial;
    }

    button {
      border-radius: 0px !important;
    }
  }
}

.mat-menu-label {
  display: block;
  margin: -0.5rem 0 0;
  padding: 0.5rem 1rem;
  font-size: 14px;
}

//.mat-menu--account,
.mat-menu--create-new {

  .mat-menu-content:not(:empty) {
    //padding: 0;
  }
}

.order-menu {

  .mat-menu-content {
    display: flex;
    align-items: center;
    padding: .5rem;
  }

  .mat-select {
    background: $app-background;
    border-radius: $border-radius;
    font-size: 18px;

    .mat-select-trigger {
      width: 48px;
      text-align: center;
    }

    .mat-select-value {
      padding: .725rem 0;
    }

    .mat-select-arrow-wrapper {
      display: none;
    }
  }

  .mat-menu-item {
    margin-left: .5rem;
    background: $primary-color;
    text-align: center;
    border-radius: $border-radius;

    &:hover:not([disabled]) {
      background: $primary-color;
    }

    [class*="fa-"] {
      color: white;
      margin: 0 auto;
    }
  }
}

.mat-menu-item {

  [class*="fa-"] {
    margin-right: 16px;
    vertical-align: middle;
    color: $primary;
    font-weight: 300;
  }
}

.mat-menu-select {

  .mat-menu-item {
    line-height: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    overflow: visible;

    .mat-checkbox-layout {
      margin: 0;
    }

    .mat-checkbox-label {
      line-height: 25px;
    }
  }
}
