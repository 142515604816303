
.mat-list {

  &-base {

    .mat-list-item{
      border-radius: $border-radius;
      overflow: hidden;

      &:hover {
        background: $accent;
      }
    }
  }

  &.mat-list--adstxt {

    .mat-list-item{
      height: 32px;
    }
  }

  &.mat-list--stages {
    padding-top: 0;

    .mat-list-item {

      .mat-list-icon {
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
      }

      .mat-list-text {}

      .mat-line {
        display: flex;
        align-items: center;

        > span {
          margin-right: auto;
        }
      }
    }
  }
}

.data-list {

  &--inline {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    dt {
      flex: 0 0 100px;
      font-size: 12px;
    }

    dd {
      flex: 1;
      word-break: break-all;
    }
  }

  .toolbar-actions & {
    margin: 0;
  }

  dt {
    margin-bottom: .25rem;
    color: $text-muted;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: .5px;
  }

  dd {
    line-height: 1.125;
    font-size: 14px;
    font-weight: 500;

    a {
      font-size: 13px;
      display: block;
      margin-top: .25rem;
    }
  }
}

.menu-info-list {
  list-style: none;
  padding: 0;
  margin: -8px 0;

  &.menu-info-list-400px {
    max-height: 400px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 14px;
    font-weight: 500;
    word-break: break-all;
    border-bottom: 1px solid $border-color;

    small {
      flex: 1 1;
      min-width: 60px; // Weird Chrome bug fix
      margin-right: .25rem;
      color: $text-muted;
      font-size: 12px;
      font-weight: 400;
    }

    strong, span {
      font-weight: inherit;
    }

    &.menu-info-list-header {
      color: $text-muted;
      border: none;
      font-size: 10px;
      padding: 0.5rem 0.5rem 0;
    }
  }
}

.vb-list {

  &.mat-list-base {
    margin: 0;
    padding: 0;

    .mat-list-item {
      font-size: 14px;
    }
  }
}

.mat-list-base {

  &.vb-selection-list {

    .mat-list-item {
      height: 60px;

      @include media-breakpoint-up(lg) {
        height: 48px;
      }

      .mat-list-text {
        //Overriding flexbox to have tooltip inline with text
        display: block;
      }
    }
  }
}

