
.mat-column {

  &-dateCreated,
  &-createdAt,
  &-createdOn,
  &-updatedOn {
    flex: 0 0 110px;
    min-width: 110px;
  }

  &-actions:last-of-type {
    flex: 0;
    min-width: 48px;
    padding-right: 0;
  }

  &-select {
    &.limited {
      flex: 0 0 60px;
      min-width: 60px;
    }
  }
}
