
.metric-panel {
	font-size: 18px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@include media-breakpoint-up(md) {
		flex-direction: column;
		align-items: center;
	}

	.metric-panel-icon {
		flex: 0 0 50px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 40px;
		margin: 0.5rem 0;

		@include media-breakpoint-up(md) {
			margin: 0.5rem;
		}

		&--green {
			background: rgba($green, .125);
			color: $green;
		}
		&--blue {
			background: rgba($blue, .125);
			color: $blue;
		}

		&--red {
			background: rgba($red, .125);
			color: $red;
		}
		&--orange {
			background: rgba($orange, .125);
			color: $orange;
		}
	}

	.metric-panel-data {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 1rem;
		flex: 1;

		@include media-breakpoint-up(lg) {
			padding-left: 0;
			flex: 0;
			align-items: center;
		}
	}

	strong {
		font-size: 2rem;
		color: $blue-gray;
	}

	small {
		color: $text-muted;
	}
}
