@use '@angular/material' as mat;
@import '../abstract/variables';
@include mat.core();

$custom-typography: mat.define-legacy-typography-config(
  $font-family: $font-family-base,
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.all-legacy-component-themes($app-theme);
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.all-component-themes($app-theme);
.mat-divider {
  border-top-color: $border-color;
}

//
//@use '@angular/material' as mat;
//
//@include mat.core();
//
//$my-primary: mat.define-palette(mat.$indigo-palette, 500);
//$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
//
//$my-theme: mat.define-light-theme((
//        color: (
//                primary: $my-primary,
//                accent: $my-accent,
//        ),
//        typography: mat.define-typography-config(),
//        density: 0,
//));
//
//@include mat.all-component-themes($my-theme);
