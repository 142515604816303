
// Importants to override material stupid * selector in Mat-List
// This is much easier and it's safe
// Dont judge please :D

.tooltip-wrapper {
  color: $info;
  font-size: 12px!important;
  margin-left: 0.25rem !important;

  &.fal {
    font-weight: 300!important;
  }
  &.far {
    font-weight: 400!important;
  }
  &.fas {
    font-weight: 600!important;
  }
}

