@mixin animation-showDown () {
  transform: translateY(-25px);
  opacity: 0;
  animation: showDown .5s forwards;

  @keyframes showDown {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@mixin animation-showInRight () {
  transform: translateX(-25px);
  opacity: 0;
  animation: showDown .5s forwards;

  @keyframes showInRight {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {

  //@include scrollbars($scrollbar-width, $scrollbar-fg, $scrollbar-bg);

  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
