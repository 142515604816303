$container-padding: 16px;

page-loader {

  .loader-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: $app-background;

    .mat-card &,
    .mat-dialog-container & {
      background-color: white;
    }

    .loader-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      font-weight: 600;
    }
  }

  .adjustment{
    margin-top: 500px;
  }
}
