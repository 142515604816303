
.link {
	color: $primary;
	cursor: pointer;
}

.hand {
	cursor: pointer;
}

.overflow-auto--mobile {
  overflow: auto;

  @include media-breakpoint-up(lg) {
    overflow: unset;
  }
}
