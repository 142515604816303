

.ng-resizable {
  position: relative
}

.ng-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;

  &.ng-resizable-e {
    cursor: e-resize;
    width: 7px;
    right: -5px;
    height: 100%;
    top: 0
  }

  &.ng-resizable-w {
    cursor: w-resize;
    width: 7px;
    left: -5px;
    height: 100%;
    top: 0
  }


  &.ng-resizable-s {
    cursor: s-resize;
    height: 7px;
    bottom: -5px;
    width: 100%;
    left: 0
  }

  &.ng-resizable-n {
    cursor: n-resize;
    height: 7px;
    top: -5px;
    width: 100%;
    left: 0
  }

  &.ng-resizable-se {
    cursor: se-resize;
    width: 12px;
    height: 12px;
    right: 1px;
    bottom: 1px
  }

  &.ng-resizable-sw {
    cursor: sw-resize;
    width: 12px;
    height: 12px;
    left: 1px;
    bottom: 1px
  }

  &.ng-resizable-ne {
    cursor: ne-resize;
    width: 12px;
    height: 12px;
    right: 1px;
    top: 1px
  }

  &.ng-resizable-nw {
    cursor: nw-resize;
    width: 12px;
    height: 12px;
    left: 1px;
    top: 1px
  }

  &.ng-resizable-diagonal {
    box-sizing: border-box;
    width: 0;
    height: 0;
    border-bottom: 12px solid $primary;
    border-left: 12px solid transparent
  }
}


