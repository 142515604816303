
.table-scroll-wrapper {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: grid;
}

.mobile-label {
  display: none;
}

.mat-paginator {
  border-radius: 0;
}

// Quick global fix. TODO @toni
.mat-expansion-panel .table-scroll-wrapper .mat-table {
  max-height: 500px;
  overflow-y: auto;
}

// ======================== MATERIAL OVERWRITE/FIXES ======================

 mat-table {

  .mat-paginator-container {
    background-color: white !important;
  }

   .mat-header-cell,
   .mat-footer-cell,
   .mat-cell {
     line-height: 1.25;
     padding: 0 $table-cell-padding-x;

     &.right-border {
      border-right: solid thin #eeeeee;
     }

     .link {
      display: flex;
      align-items: center;
      
      .mat-icon {
        margin-right: 0.5rem;
      }
     }
   }

   .mat-header-cell {

     &.mat-cell--number {
       justify-content: flex-end;

       .mat-sort-header-button {
         text-align: right;
       }
     }
   }

   .mat-cell,
   .mat-footer-cell{

     [class*="fa-"] {
       //font-size: 1rem;
     }

     &.mat-cell--number {
       justify-content: flex-end;
       padding-right: 1.5rem;

       &:last-of-type {
         padding-right: 2.5rem;
       }
     }

     //&.mat-cell--date-time {
     //  flex-direction: column;
     //  align-items: flex-start;
     //  justify-content: center;
     //}
   }


   /* When table needs to scroll horizontally on smaller screens,
      row does not expand to full with of content, so the border looks broken.
      Setting the border-bottom to table cell fixes that issue.
    */
   mat-row,
   mat-header-row,
   mat-footer-row,
   th.mat-header-cell,
   td.mat-cell,
   td.mat-footer-cell {
     border-bottom:none;

     mat-cell,
     mat-footer-cell,
     mat-header-cell {
       border-bottom: 1px solid $border-color;
     }

     &:hover:not(.no-hover) {

       mat-cell {
         background: $table-row-hover;
       }
     }
   }
 }

 .border-top {
  border-top: solid thin #eeeeee !important;
 }