
.mat-expansion-panel + .mat-expansion-panel {
  margin-top: -1px; // visually remove double border
}

.mat-sidenav-content {

  .mat-accordion {

    .mat-expansion-panel {

      border-radius: 0px !important;

      &:first-of-type {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }
  }


  .mat-expansion-panel {

    &:not([class*='mat-elevation-z']) {
      box-shadow: $box-shadow;
      //border: 1px solid $border-color;
    }
  }

  .full-panel-body {

    .mat-expansion-panel-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .mat-expansion-panel-header {
    //border-bottom: 1px solid $border-color;
    font-weight: 600;

    .mat-content {
      align-items: center;
      font-size: 16px;
      font-weight: inherit;
    }

    &.mat-expanded {
      margin-bottom: .5rem;
    }
  }

  .mat-expansion-panel-header-title {
    align-items: center;
    flex: 1;
    font-weight: inherit;
  }

  .mat-expansion-panel-header-description {
    flex: 0 0 auto;
    align-items: center;
    padding-right: 0.5rem;
    font-size: 80%;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      padding-right: 1rem;
    }
  }

  .mat-expansion-panel-body {

    .mat-action-row {
      margin-top: -1px;
      margin-bottom: -1rem;
      padding-right: 1rem;
    }
  }

  .mat-expanded .mat-expansion-panel-content {
    //padding-top: 1.5rem;
  }
}

.app-nav-accordion {

  .mat-expansion-panel {
    background: transparent;
    border-radius: 0 $border-radius $border-radius 0;

    &:first-of-type,
    &:last-of-type {

      .sidenav-menu & {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }

    &.mat-expanded {
      background: white;
      padding-bottom: 10px;
    }
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }


  .mat-expansion-panel-header {
    max-height: 48px;
    padding: 0;
    border-bottom: none;
    border-radius: 0;
    margin: 0;

    &:hover {
      // Using important because of high specificity from angular
      // We are 1000% sure it's fine to use it here because of parent class
      background: none !important;
    }

    .sideClosed & {
      // display: none;
    }

    @include media-breakpoint-down(md) {
      // display: none;
    }
  }

  .mat-expansion-panel-header-title {
    border: none
  }

  .mat-expansion-indicator {
    display: none;
  }

  .mat-expansion-panel-content {
  }

  .mat-expansion-panel-body {
    margin: 0;
    padding: 0;
  }
}

.mat-dialog-content {

  .mat-expansion-panel-header {
    padding: 0 1.5rem;
    margin-bottom: 0;

    &.mat-expanded {
      margin-bottom: 0;
    }
  }

  .mat-expansion-panel-body {
    // Dont change this. If you need padding, use Bootstrap "p-" class on wrapper in html
    padding: 0;
  }

  //.mat-expansion-panel-content {
  //  padding: 0 1.5rem;
  //}
}

.mat-accordion--nested {

  .mat-expansion-panel {

    &:first-of-type {
      border-radius: 0;
    }

    .mat-expansion-panel {
      box-shadow: none;
      border: none
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    margin-bottom: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}
