
body {

  .cdk-overlay-pane {
    // Prevent from showing dialog actions out of screen
    max-height: 96vh;
    max-width: 90vw;

    &.creative-preview-panel {
      width: 90vw;

      @include media-breakpoint-up(lg) {
        width: 60vw;
      }
    }
  }

  .mat-dialog-container {
    border-radius: $border-radius;
    position: relative; // Needed for page loader
  }

  .mat-dialog-header {
    margin: -24px -24px 0;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .mat-dialog-title {
      padding: 1rem;
      margin: 0 auto 0 0;
    }
  }

  .mat-dialog-header-box {
    display: flex;
    align-items: center;
    border-top: 1px solid $border-color;

    @include media-breakpoint-up(lg) {
      border-top: none;
    }
  }

  .mat-dialog-title {
    font-weight: 600;

    &.has-action {
      margin: -1.5rem -1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mat-form-field {
      font-size: 1rem;
    }
  }

  .mat-dialog-subtitle {
    display: block;
    font-size: 14px;
    line-height: 1;
  }

  .mat-dialog-content {
    max-height: 75vh;
  }

  .mat-dialog-actions {
    justify-content: end;
    //margin-top: 1rem;

    /*.mat-button-base+.mat-button-base,
    .mat-mdc-button-base+.mat-mdc-button-base {
      // Angular override
      margin-left: 1rem;
    }*/
  }

  .momentum-dialog {
    // For some reason, adding these 4 to "new-line-item.ts" file is not working
    max-width: 100vw!important;
    max-height: 100vh!important;
    width: 100vw!important;
    height: 100vh;

    .mat-dialog-container {
      border-radius: 0;
      padding: 0;
    }

    .mat-dialog-header {
      margin: 0;

      @media only screen and (min-width: 62em) {
        height: 64px;
      }
    }

    .mat-dialog-content {
      margin: 0;
      max-height: none;
      flex-grow: 1;
    }
  }
}

.discrepancies-dialog {

  .mat-dialog-container {
    padding: 0;
  }
}

.fullscreen-dialog {
  width: 100%!important;
  height: 100%!important;
  max-width: 100%!important;
  max-height: 100%!important;

  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }

  .mat-dialog-header {
    position: sticky;
    z-index: 10;
    top: 0;
    background: white;
    margin: 0;
  }

  .mat-dialog-content {
    max-height: 100%;
    flex-grow: 1;
    margin: 0;
  }

  .mat-dialog-actions {
    position: sticky;
    z-index: 10;
    bottom: 0;
    margin: 0;
    background: white;
    border-top: 1px solid $border-color;
  }
}
