@import "../abstract/variables";

body {

  .mat-card {
    border-radius: $border-radius;

    &.mat-focus-indicator {
      border-radius: 0px !important;
    }

    &:not([class*='mat-elevation-z']) {
      box-shadow: $box-shadow;
    }

    &.no-shadow {
      box-shadow: none !important;
      border-radius: 0px !important;
    }

    .mat-card-header {
      flex-wrap: wrap; // header-info new line for mobile
      align-items: center;
      margin: -1rem -1rem 0;
      padding: 0 1rem;
      border-bottom: 1px solid $border-color;

      @include media-breakpoint-up(lg) {
        height: 4rem;
      }

      + .mat-card-header {
        margin-top: 0;
      }

      // Special case for line items table
      &.mat-card-header--second {
        flex-direction: column;
        padding: 0;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
        }

        .mat-tab-label {
          flex-grow: 1;
        }
      }
    }

    .mat-card-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.5rem;
      margin-bottom: 0;

      button {
        margin-left: 1rem;
      }
    }

    .mat-card-header-text {
      margin: 0;
      flex-grow: 1;
      padding: 1rem 0;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }

      &:empty {
        // Hide element if empty because of flex
        display: none;
      }
    }

    .mat-card-header-buttons {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
        margin-right: 1rem;
      }
    }

    .mat-card-header-data,
    .mat-card-header-status,
    .mat-card-header-datepicker,
    .mat-card-header-buttons-toggle {
      order: 1;
      flex: 1 1 100%;
      display: flex;
      padding-bottom: 1rem;
      overflow: auto;

      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        padding-bottom: 0;
      }
    }

    .mat-card-header-datepicker{
      overflow: unset;
    }

    .mat-card-header-search {
      order: 1;
      flex: 1 1 100%;
      display: flex;
      margin-left: 0px;

      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        margin-left: -2px;
      }
    }

    .mat-card-header-actions {
      order: 0;
      margin: 0 -8px 0 0;

      @include media-breakpoint-up(lg) {
        order: 1;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin: 0;
      }

      > * + * {
        // In 99% cases these are buttons but sometimes,
        // we have a component like "mt-simple-mat-table-col-vis" which isn't a button
        margin-left: .5rem;
      }
    }

    .mat-card-header-info {
      order: 2;
      // flex: 1 1 100%;
      // margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        order: 0;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        margin: 0;
      }
    }

    .mat-card-header-tabs {

    }

    .mat-card-content {

      &--full {
        margin: 0 -1rem;
      }
    }

    .card-message {
      padding: 3rem 2rem 2rem; //top 3rem cause of mat-content bottom padding
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
