

.mat-snack-bar-container {
  border-radius: $border-radius;

  &.snackbar-error {
    background: $danger;
    color: white;
  }

  &.snackbar-success {
    background: $success;
    color: white;
  }

  &.snackbar-info {
    background: $info;
    color: white;
  }
}
