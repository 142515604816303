.text-green {
  color: $green;
}
.text-orange {
  color: $orange;
}
.text-red {
  color: $red;
}
.text-gray {
  color: $bluegray;
}
.text-lightgray {
  color: $gray-lighter;
}
.text-blue {
  color: $blue-light;
}
