
textarea {
  min-height: 124px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}

.form-group-header {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 4px;
    font-weight: 500;
    line-height: 1.25;
  }

  small {
    display: block;
    color: $text-muted;
    margin-bottom: 2px;
  }
}

.form-group {

  .mat-form-field-appearance-outline {

    &:not(.mat-form-field-has-hint) {

      .mat-form-field-wrapper {
        margin: 0;
        padding-bottom: 0;
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  hr {
    width: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

/* Subgroups*/

.form-subgroup {

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }
}

.form-subgroup-row {
  position: relative;
  padding-top: 1.5rem;
  padding-left: 24px;


  &:before {
    content: "";
    display: block;
    width: 12px;
    height: 30px;
    position: absolute;
    left: 13px;
    top: 6px;
    border-bottom: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-radius: 0 0 0 10px;
  }

  &:not(:only-of-type):not(:last-of-type):after {
    content: "";
    position: absolute;
    top: 6px;
    left: 13px;
    height: 100%;
    border-left: 1px solid $border-color;
    z-index: 1;
  }

  /*+ .form-subgroup-row {

    &:before {
      height: 100px;
      top: -90px;
    }

    &.tree-detail-small {

      &:before {
        height: 60px;
        top: -50px;
      }
    }

    &.tree-detail-150 {

      &:before {
        height: 150px;
        top: -140px;
      }
    }

    &.tree-detail-250 {

      &:before {
        height: 250px;
        top: -240px;
      }
    }

    &.tree-detail--pmp-deal {

      &:before {
        height: 430px;
        top: -420px;
      }
    }
  }*/

  .form-group-header {
    // padding-left: 2rem;
  }
}

.mat-form-field {

  .mat-toolbar & {
    font-size: 14px;
  }

  &--no-underline {

    .mat-form-field-underline {
      display: none;
    }

    .mat-flat-button & {
      height: 40px;
      margin-top: -14px;
      font-weight: 500;
    }
  }
}

.mat-checkbox-layout {

  .mat-dialog-title & {
    margin-bottom: 0;
  }
}

.simple-form-field,
.header-form-field,
.search-form-field {
  border: none;
  padding: 3px 10px;
  height: 64px;

  @include media-breakpoint-up(lg) {
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }

  .mat-form-field-prefix {
    margin-right: 10px;
    color: #888;
  }

  .mat-form-field-underline {
    display: none;
  }

  + .header-form-field {
    margin-left: -1px;
  }

  .mat-card-header-search & {
    flex-grow: 1;
    margin: 0 -1rem;
    padding: 0 1rem;
    border-top: 1px solid $border-color;
    border-left: none;

    @include media-breakpoint-up(lg) {
      border-top: none;
      margin: 0;
      padding: 3px 10px;
    }
  ;
  }
}

.vb-header-search-field {

  &.mat-form-field-appearance-legacy {

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: none;
    }
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-chip-list-wrapper {

  .mat-form-field {
    margin: 0 4px -4px;

    input.mat-input-element {
      width: 100%;
      margin: 0;
    }
  }
}

/* Find a better way then connecting 2 classes to lower specificity */
.mat-form-field.mat-form-field-appearance-outline {

  .mat-form-field-infix {
    padding: 0.875rem 0;
    border-top-width: 0.5675em;
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-end {
    min-width: 12px;
  }

  .mat-form-field-outline-start{
    border-radius: $border-radius 0 0 $border-radius;
  }

  .mat-form-field-outline-end {
    border-radius: 0 $border-radius $border-radius 0;
  }

  .mat-form-field-prefix {
    top: 0;
    transition: top 0.25s;
  }

  .mat-form-field-suffix {
    top: 0;

    .dashboard-view & {}

    .double-suffix-actions {
      display: flex;
      align-items: center;
      position: relative;
      top: -4px;
      right: -6px;

      .mat-icon-button {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 0.375em;
  }

  &.mat-form-field-hide-placeholder {

    .mat-form-field-prefix {
      top: -0.125rem;
    }
  }

  // This line makes sure the label is moved only after it "floats" to top
  &:not(.mat-form-field-hide-placeholder) {

    // When input field is prefixed, there is small overlapping of label with border, this is a fix
    .mat-form-field-label:not(.mat-form-field-prefix + .mat-form-field-infix .mat-form-field-label) {
      padding-left: 6px;
      padding-top: 2px;
      color: #5f6368;
    }
  }

  &.endpoint-macro-input {

    .mat-form-field-wrapper {
      padding: 0;
    }

    .mat-form-field-infix {
      border: none;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(2px);
    }

    .mat-form-field-outline {
      color: transparent;

      .endpoint-macro-row:hover & {
        color: $border-color;
      }
    }
  }
}

// ======================== MAT-AUTOCOMPLETE-PANEL ======================

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  border-radius: 12px;
  margin-top: -2px;
}
