@import "../abstract/variables.scss";

.date-selector {
  position: relative;
  background: #ffffff;
  border-radius: 20px;

  .date-preview {
    display: flex;
    align-items: center;

    .mat-button-wrapper {
      display: flex;
    }
  }

  .date-range-icon {
    margin-left: 1rem;
  }
}

.date-range-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .date-selector & {
    flex-direction: column;
    background: $white;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    box-shadow: $box-shadow !important;
    z-index: 1000;
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
      right: 0;
      transform: none;
    }
  }

  .mat-icon-button {
    padding: 0;
    line-height: 40;
  }
}

.date-range-buttons {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    // padding: 0 0.675rem;
  }

  @include media-breakpoint-up(xxxl) {
    // padding: 0.675rem;
  }

  .mat-button-toggle {
    flex: 0 0 50%;

    @include media-breakpoint-up(md) {
      flex: 0 0 25%;
    }

    @include media-breakpoint-up(xxxl) {
      flex: 0 0 auto;
    }
  }

  .date-selector & {
    padding: 1rem;

    > button {
      flex: 0 0 50%;
    }
  }

}

.date-range-form-field {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: auto;
  }

  .date-selector & {
    padding: 1rem;
    border-top: 1px solid $border-color;
  }
}
