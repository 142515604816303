
.sideClosed ~ .mat-sidenav-content {

	@include media-breakpoint-up(lg) {
		margin-left: $sidebar-width-collapsed - 14px;
	}
}

.main{
	min-height: 100vh;
	background: #f0f4f8;
  	padding-bottom: 24px;

  @include media-breakpoint-up(lg) {
    padding-right: 0;
    padding-left: 0;
  }
}

.main-content{
	padding: 0;

	@include media-breakpoint-up(lg) {

	}

	.partners &{
		padding: 1rem 0;
	}

  &.main-content--has-tabs {
    padding: 0;
  }
}

.content-section {
	margin-bottom: 2rem;
}



.buttonLoader:after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid #ffffff;
	border-top-color: $primary;
	animation: spinning .8s linear infinite;
  }
  
  @keyframes spinning {
	to {transform: rotate(360deg);}
  }
  