
.mat-stepper-horizontal {

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background: none;
  }
}

.mat-step-header {

  .mat-step-text-label {
    font-weight: bold;
  }
}

.mat-step-icon-state-edit {

  .mat-step-icon-content {
    font-size: 10px;
  }
}

.stepper-step-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.stepper-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  .mat-button,
  .mat-raised-button {
    padding: 0 2rem;
  }
}

.mt-vertical-stepper {
  .mat-vertical-content {
    padding: 24px;
  }
}

.vertical-step-actions {
  margin-top: 1rem;
  margin-bottom: -0.5rem;
}
