
.mobile-top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: $mobile-top-bar-height;
  padding: 0 5px;
  background: $primary;
  color: white;
  border-radius: 0 0 $border-radius $border-radius;
  transition: all .33s ease-in-out;

  &.fixed {
    box-shadow: 0 0 25px rgba(122, 119, 119, 0.5)
  }
}

.app-nav-accordion .mat-expansion-indicator {
  display: block !important;
  position: absolute;
  left: 9px;

  &:after {
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: "";
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg) translateY(-2px);
  }
}

.mat-sidenav-content {
  padding-top: $mobile-top-bar-height;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

.mat-sidenav-container {
  min-height: 100vh;
}

.mat-drawer-inner-container {
  @include scrollbars($scrollbar-width, $scrollbar-fg);
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding-right: 10px;
  border-right: solid 1px #dfdfdf;
}

.app-nav-accordion .mat-expansion-panel.mat-expanded {
  background-color: initial !important;
}

body .mat-sidenav {
  position: fixed;
  width: $sidebar-width;
  background-color: $sidebar-background;
  color: $sidebar-link-color;
  padding: 0;
  border-right: none;
  border-radius: 0 $border-radius $border-radius 0;

  @include media-breakpoint-down(md) {
    z-index: 999999999 !important;
    margin-top: 50px;
  }

  .header-user {
    svg {
      height: 44px;
      margin-left: 10px;
    }
  }

  .account-switcher {
    margin-bottom: 1rem;
    padding: 10px 0 10px 10px;
    border-bottom: solid thin lightgrey;

    .header-user-icon {
      font-size: 22px;
    }

    .mat-expansion-indicator {
      &:after {
        border-style: solid;
        border-width: 0 3px 3px 0;
        content: "";
        display: inline-block;
        padding: 2px;
        transform: rotate(45deg) translateY(-2px);
      }
    }

    .mat-expansion-panel {
      box-shadow: none;
      background-color: white;
    }

    .mat-expansion-panel-body {
      padding: 0;

      .mat-list-item-content {
        font-size: 14px;
      }
    }
    .mat-expansion-panel-header {
      padding: 0 10px;
      border-radius: 0px;

      &:hover {
        background-color: $gray-lightest;
      }

      &.mat-expanded {
        height: 48px;
      }
    }

    .mat-list-base {
      .mat-list-item {
        border-radius: 5rem;

        &.sign-out-button {
          &:hover {
            background-color: #fce8e6;
          }
        }
      }
    }
  }


  .sidenav-logo {
    padding: 1.5rem 1rem;
    // background: white;
    border-radius: 0 0 12px 0;
    margin-bottom: 0.25rem;

    img {
      max-width: 128px;
    }
  }

  .sidenav-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    padding: 1rem 0 1rem 0rem;
    height: auto;
    background-color: white;
    border-bottom: solid thin lightgrey;

    &:focus {
      outline: none;
    }

    .mat-mini-fab {
      box-shadow: none;
      border-radius: $border-radius;
      color: $primary;
      left: 6px;
      transition: all .3s;
    }
  }

  .header-user {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-user-info {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    padding: 0 .5rem;
    overflow: hidden;
    justify-content: center;
  }

  .user-info-name {
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .user-info-role {
    color: #888;
    font-size: 10px;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user {
    flex: 1;
    overflow: hidden;

    p {
      margin: 0;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      line-height: 1.25;
    }

    .sidenav-header-username {
      color: inherit;
      font-weight: bold;
      font-size: 13px;
      text-transform: capitalize;
    }

    .sidenav-header-publisher {
      font-size: 10px;
      color: #888;
    }
  }

  .sidenav-menu {
    list-style: none;
    padding: 0;
    margin-bottom: auto;
    margin-top: .5rem;
    margin-right: .5rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-right: 0;
    }

    .sidenav-menu-item {
      display: flex;
      align-items: center;
      height: 48px;
      margin: 0;
      padding: 0 0.75rem 0 1.25rem;
      color: rgb(32, 33, 36);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      border: none;
      text-decoration: none;
      border-radius: 0 50px 50px 0;

      i {
        width: 30px;
        margin-right: 6px;
        text-align: center;
        font-size: 16px;
        color: #888;
      }


      &:hover {
        background-color: $gray-lightest;
      }

      &.active {
        background-color: $primary;
        color: white;

        i {
          color: white;
        }
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;

      }
    }

    .sidenav-submenu {
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0;

      a {
        display: flex;
        align-items: center;
        padding: 0.75rem 0.75rem 0.75rem 2rem;
        color: inherit;
        font-size: 13px;
        font-weight: 500;
        position: relative;
        border-radius: 0 30px 30px 0;

        > i {
          margin: 0 16px 0 0;
          font-size: 8px;
          color: #CCC;
        }

        &:hover {
          background-color: $gray-lightest;
          text-decoration: none;
        }

        &.active {
          background-color: $primary;
          color: white;
          text-decoration: none;

          > i {
            color: white;
          }
        }

        &.disabled {
          opacity: .5;
          pointer-events: none;

        }
      }
    }
  }

  .active ul {
    display: block !important;
  }

  &.sideClosed {

    @include media-breakpoint-up(lg) {
      margin-left: $sidebar-width-collapsed;
      // Need this to counter angular attr selector and inline styles
      visibility: visible !important;
      display: block;

      .mat-mini-fab {
        left: 0;
      }

      .sidenav-menu {
        display: none; // Temp fix
        list-style: none;
        margin-top: 20px;
        padding: 0;

        .sidenav-menu-item {
          flex-direction: row-reverse;
          font-size: 0;

          i {
            width: 36px;
            font-size: 14px;
            text-align: center;
          }

          span {
          }
        }
      }
    }
  }
}
