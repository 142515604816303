

.mat-badge-color {

  &--muted {

    .mat-badge-content {
      background: #888;
    }
  }

  &--active {

    .mat-badge-content {
      background: $status-color-active;
    }
  }

  &--inactive {

    .mat-badge-content {
      background: $status-color-inactive;
    }
  }

  &--archived {

    .mat-badge-content {
      background: $status-color-archived;
    }
  }
}
