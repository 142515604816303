@import "../abstract/variables";

mt-toolbar.fixed-top {
  position: static;

  @include media-breakpoint-up(lg) {
    position: fixed;
    left: 180px;
  }
}

mt-toolbar.fixed-top + .main-content {

  @include media-breakpoint-up(lg) {
    padding-top: calc(64px + 2rem);
  }
}

mt-toolbar {

  .mat-toolbar {
    background: white;
    border-bottom: none;
    position: relative;
    height: auto;
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
      padding: 0;
      padding-top: 3rem;
    }

    .mat-toolbar-row {
      flex-wrap: wrap;
      height: auto;
      border-bottom: solid thin #dfdfdf;

      &:nth-child(2) {
        background-color: #ffffff;
        padding-top: 1.5rem;
        padding-bottom: 1rem;
        border-top: solid thin #dfdfdf;
        border-bottom: solid thin #dfdfdf;
      }

      &:first-of-type {

        .partners & {
          height: 8rem; // Set to 8 when tabs are placed back in partner dash
          border-bottom: 1px solid $border-color;

          @include media-breakpoint-up(lg) {
            height: 4rem;
          }
        }
      }
    }

    .mat-toolbar-row--one {

      @include media-breakpoint-up(lg) {
        min-height: 80px;
      }
    }

    .mat-toolbar-row--two {
      // margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        margin-top: -0.5rem;
      }

      &:empty {
        display: none;
      }
    }

    .mat-toolbar-header {
      display: flex;
      align-items: center;
    }

    .mat-toolbar-title {
      font-size: 22px;
      font-weight: 600;
      // color: $primary-color;
      white-space: normal;
      line-height: 1;
    }

    .page-title {

      .partners & {
        text-transform: uppercase;
      }
    }

    .mat-tab-nav-bar {
      border: none;

      .mat-tab-link {
        height: 4rem;
        color: $blue-gray;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        text-decoration: none;
      }

      .mat-tab-label-active {
        color: $black;
        opacity: 1;
      }
    }

    .toolbar-info {

      &:empty {
        display: none;
        margin: 0 !important;
      }
    }

    .toolbar-actions {
      font-size: 1rem; // some elements inherit toolbar font size which is big (ex. slide-toggle)
      display: flex;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }

      &:empty {
        display: none;
        margin: 0 !important;
      }
    }

    [toolbar-actions] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }

      .publisher-details & {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }


    .title-seperator {
      color: rgba($text-muted, 0.5);
      font-size: 13px;
      margin: 0 0.5rem 0 0.675rem;
      vertical-align: middle;
      line-height: 27px;
    }

    .mat-toolbar-status-circle {
      font-size: 16px;
    }
  }

  /* Special screens where we hide 2nd row and or actions on mobile*/
  &.a2v-create-edit-brand,
  &.a2v-network-template,
  &.publisher-details {

    .mat-toolbar-row--two,
    .toolbar-actions {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
  }

  &.order-details,
  &.connection-details,
  &.network-details,
  &.placement-new-edit{

    .mat-toolbar-row--two {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
  }
}


.mat-toolbar-back {
  margin: 0 0.5rem 0 -0.5rem;

  .toolbar-back-btn {
    background-color: transparent !important;
    color: $gray-light !important;
    border-radius: 50%;
  }

  .sidenav-closed & {
    margin-left: -0.25rem;
  }
}

.toolbar-img {
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  object-fit: cover;
  border-radius: $border-radius;
}

.toolbar-dl {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 1rem;

  &--inline {
    flex-direction: row;
    align-items: center;

    > dt {
      margin-right: .5rem;
    }
  }

  dt {
    color: $text-muted;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
  }

  dd {
    margin: 0;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 600;
  }
}

.toolbar-action-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-right: -1rem;

  @media only screen and (min-width: 62em) {
    // border-left: 1px solid $border-color;
  }
}
