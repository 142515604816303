
[formfieldappearance="outline"] {

  .mat-chip-list:not(.form-field-chip-list) {
    display: block;
    border: 1px solid $border-color;
    border-radius: 0 0 5px 5px;
    padding: 0.675rem 0.5rem 0.5rem;
    margin-top: -24px!important;
    background: #FAFAFA;
    border-top: none;

    .mat-basic-chip {
      background: whitesmoke;
      border: 1px solid $border-color;
    }
  }
}

.mat-chip-list {
  display: block;
}

.mat-basic-chip {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: .25rem;
	padding: 0.75rem 1.25rem;
	background: whitesmoke;
	border: 1px solid $border-color;
	border-radius: 25px;
	line-height: 1;

	.mat-chip-remove {
		margin: 0 -.5rem 0 0.5rem;
    opacity: .25;
    cursor: pointer;
	}

  &:hover {

    .fas {
      opacity: 1;
    }
  }

  .mt-card-filters & {
    border: 1px solid $border-color;
    background: #f9f9f9;
  }

  .mat-chip-list-stacked & {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.mat-chip-list-stacked {

  .mat-chip-list-wrapper {
    margin-left: 0;
    margin-right: 0;
  }
}
