
.mat-radio-group {

  &--stacked {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.mat-radio-label {

  .content-browser-wrapper & {
    margin-bottom: 0;
  }
}
