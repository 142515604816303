button {

  &.radius-rounded {
    border-radius: 40px !important;
  }

  &.iconed .mat-button-wrapper > {
    .mat-icon,
    [class*="fa-"]{
      margin-right: 10px;
    }
  }

  &.large {
    line-height: 48px;
    padding: 0 1.75rem;
  }

  .toolbar-actions & {
    min-height: 40px;
    border-radius: 40px !important;
  }

  .mat-mini-fab & {
    border-radius: 40px !important;
  }
}

.alert.alert-dismissible .close {
  padding: .5rem .75rem;
}

.mat-flat-button {

  .toolbar-actions & {
    min-height: 40px;
  }
}

body {

  .mat-flat-button.mat-accent,
  .mat-raised-button.mat-accent,
  .mat-fab.mat-accent,
  .mat-mini-fab.mat-accent {
    color: $primary;
  }

  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button {
    border-radius: $border-radius;

    &.mobile-fab {
      position: fixed;
      z-index: 10;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 2px 4px #7a7a7a;

      @include media-breakpoint-up(lg) {
        position: static;
        transform: unset;
        box-shadow: none;
      }
    }
  }

  .mat-mini-fab {
    border-radius: 40px;

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}


