@use '@angular/material' as mat;
@mixin button-toggle($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);

  .mat-button-toggle {
    background-color: white;
    color: mat.get-color-from-palette($primary);

    .mat-button-toggle-label-content {
      font-size: 14px;
      font-weight: 500;

      @media only screen and (min-width: 62em) {
        padding: 0 24px;
      }
    }
  }

  .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette(mat.$blue-grey-palette, 50);

    .mat-button-toggle-label-content {
      color: white;
    }
  }

  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    border-radius: $border-radius;
  }

  .mat-button-toggle-appearance-standard {

    .mat-button-toggle-label-content {
      line-height: 40px;
    }
  }

  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay {
    background: mat.get-color-from-palette($primary);
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    border-left-color: #FFF;
  }
}

body {
  // Must be inside of body or any other parent element to override the material default
  @include button-toggle($app-theme);

  .mat-button-toggle-group {

    // Related to "date_selector.scss" .date-range-buttons class
    &.date-range-chooser-toggle-group {
      margin-top: 5px;
      border-radius: 12px;
      flex-wrap: wrap;

      @include media-breakpoint-up(xxxl) {
        margin-top: -7px;
        line-height: 46px;
      }
    }

    &.stretched-buttons{

      .mat-button-toggle {
        flex-grow: 1;
      }
    }
  }

  .mat-button-toggle-group.align-block-toggle-group {
    border: none;
    border-radius: 0;

    .mat-button-toggle {
      height: 28px;
      color: #888;
      border-radius: 6px;
    }

    .mat-button-toggle-label-content {
      padding: 0 8px;
      line-height: 28px;
    }

    .mat-button-toggle-checked {
      background: white;
      color: $primary;

      .mat-button-toggle-label-content {
        color: currentColor;
      }
    }
  }
}
