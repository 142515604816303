@import 'abstract/variables';

.action-link {
  color: $primary-color;
  text-decoration: underline;
  cursor: pointer;
}

.no-link-decoration {
  &:hover {
    text-decoration: none;
  }
}
