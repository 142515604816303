@import 'abstract/variables';

.vb-header {
  display: flex;
  align-items: center;
  border: 1px solid $platinum-color;
  padding: 8px 12px;

  &.is-selected {

  }
}
