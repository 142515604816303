

.avatar-circle-group {
  display: flex;
}

.avatar-circle {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $accent;
  color: $primary;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  border: 1px solid white;

  &:not(:first-of-type) {
    margin-left: -8px;
  }
}

