
.page-loader {
  position: fixed; /* Changed from absolute to fixed to ensure it's always relative to the viewport */
  z-index: 1000000; /* Keeps it above other content */
  top: 50px; /* Offset from the top */
  left: 230px; /* Align to the right edge of navigation */
  right: 0; /* Align to the right edge of the viewport, ensures full width */
  bottom: 0; /* Align to the bottom edge of the viewport, ensuring full height with top offset */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  background: $app-background; /* Use your application's background variable */
  overflow: hidden;

  .mat-dialog-container & {
    width: auto;
    height: auto;
    top: -24px;
    right: -24px;
    bottom: 0;
    left: -24px;
  }
}

.data-loader {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2.5rem 0;

  .mat-card & {
    margin-top: 1rem;
  }
}

.data-msg {
  padding: 1rem;

  .mat-tab-body-content & {
    padding-top: 0.5rem;
  }

  .mat-expansion-panel-body & {
    padding: 0 1.5rem;
  }

  h6, h5, .data-msg-title {
    color: $info;
    font-weight: 500;
  }

  p, .data-msg-text{
    color: $text-muted;
  }
}

.data-alert {
	@extend .data-loader;
	color: $warning;
	font-size: 1rem;
	font-weight: 400;
}

.data-alert-wrapper {
  // max-width: 300px;
  margin: 0 auto;
}
