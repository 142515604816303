@import 'abstract/variables';
@import 'abstract/mixins';
@import 'abstract/helpers';
@import 'vendors/bootstrap';
@import 'components/cards';
@import 'components/panels';
@import 'components/buttons';
@import 'components/tables';
@import 'components/table_columns';
@import 'components/menu';
@import 'components/dialog';
@import 'components/expansion-panel';
@import 'components/data-messages';
@import 'components/chips';
@import 'components/form';
@import 'components/tabs';
@import 'components/list';
@import 'components/status';
@import 'components/checkbox';
@import 'components/upload';
@import 'components/radio';
@import 'components/date_selector';
@import 'components/stepper';
@import 'components/text-colors';
@import 'components/header';
@import 'components/link';
@import 'utils/fonts';
@import 'components/text';
@import 'components/badge';
@import 'components/snackbar';
@import 'components/page-loader';
@import 'components/tooltips';
@import 'components/slide-toggle';
@import 'components/button-toggle';
@import 'components/resizable';
@import 'components/avatar';

@import 'layout/main-content';
@import 'layout/sidebar';
@import 'layout/toolbar';

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  overflow-x: hidden;

  @include scrollbars($scrollbar-width, $scrollbar-fg, $scrollbar-bg);

  .app-wrapper {
    min-height: 100vh;
    background-color: inherit;
  }

  .mat-drawer-container {
    background: inherit;
  }
}

button:focus {
  outline: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.hide-arrow .mat-select-arrow {
  display: none;
}

a.link {
  color: $primary-color !important;
  cursor: pointer;
}

.body-form .mat-tab-body-wrapper {
  //min-width: 1200px;
  min-width: 200px;
  margin: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}

.mat-chip.status-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 3px 10px;
  border-radius: 33px;
  border: 1px solid transparent;
  align-items: center;
  cursor: default;
  min-height: 24px;
  height: 1px;
  color: #5f6368 !important;
  background-color: #f1f3f4 !important;
  font-size: .75rem;
  font-weight: 500;
}

.mat-chip.status-chip.active {
  color: $active-text !important;
  background-color: $active-bg !important;
}
.mat-chip.status-chip.inactive {
  color: $inactive-text !important;
  background-color: $inactive-bg !important;
}

.mat-chip.status-chip.processing {
  color: $red !important;
  background-color: $yellow !important;
}

.mat-chip.status-chip.failed {
  color: $red !important;
  background-color: $red-light !important;
}

.mat-chip.status-chip.unapproved {
  color: $red !important;
  background-color: $red-light !important;
}

.mat-chip.status-chip.approved {
  color: $blue !important;
  background-color: $blue-light !important;
}

.mat-chip.status-chip.processed {
  color: $blue !important;
  background-color: $blue-light !important;
}

.mat-chip.status-chip.new {
  color: $active-text !important;
  background-color: $active-bg !important;
}

.mat-chip.status-chip.ai_video {
  color: $active-text-ai !important;
  background-color: $active-bg-ai !important;
}

.mat-chip.status-chip.collections {
  color: $inactive-text-orange !important;
  background-color: $inactive-bg-orange !important;
}

.mat-tooltip {
  white-space: pre-line;
}
// :root {
//   --mum-qr-fg:#000;
//   --mum-qr-bg:#fff;
// }
.mat-button-focus-overlay {
  background-color: transparent!important;
}

/* For Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 10px;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background:darken($app-background, 5%); /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #AAA; /* Color of the scrollbar itself */
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888; /* Color when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
  box-shadow: none !important;
}

.sidenav-closed .mat-sidenav-content {
  /* Ensure sidenav is completely hidden when closed */
  margin:0px !important;
}

.mat-card {
  box-shadow: none !important;
  border-radius: 10px !important;
}

body .mat-card .mat-card-header {
  justify-content: space-between;
}

body button {
  border-radius: 40px !important;
}

.mat-select-panel, .mat-autocomplete-panel, .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important
}