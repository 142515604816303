.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-subtitle {
  font-size: 14px;
}


b, strong {
  // font-weight: 500;
}
