
.mt-upload-button-label {
  font-size: 12px;
}

.mt-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 1rem;
  background: #f7f7f7;
  border: 1px dashed darken($app-background, 5%);
  border-radius: $border-radius;
  //border: 1px solid $border-color;
  cursor: pointer;

  &:hover {
    background: $app-background;
  }
}

.mt-upload-preview {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px dashed $border-color;
  border-radius: $border-radius;


  .mt-upload-preview-image {
    position: relative;
    padding: 0;
    background: #000;

    img {
      transition: all .33s;
    }
  }

  .mt-upload-preview-name {
    word-break: break-all;

    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .mt-upload-preview-delete {
    position: absolute;
    top: 15%;
    left: 90%;
    transform: translate(-50%,-50%);
    opacity: 0;
    transition: all .33s;
  }

  &:hover {

    .mt-upload-preview-image img {
      opacity: .25;
    }

    .mt-upload-preview-delete {
      opacity: 1;
    }
  }
}
