@use '@angular/material' as mat;

$app-primary: mat.define-palette(mat.$blue-palette, 600);
$app-accent: mat.define-palette(mat.$blue-grey-palette, 50);
$app-warn: mat.define-palette(mat.$amber-palette, 600);
$app-info: mat.define-palette(mat.$cyan-palette, 500);

$app-theme: mat.define-light-theme((
        color: ( primary: $app-primary,
                 accent: $app-accent,
                 warn: $app-warn),
        typography: mat.define-typography-config(),
        density: 0,
));

$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);
$info: mat.get-color-from-palette($app-info);
$blue-gray: mat.get-color-from-palette(mat.$blue-grey-palette, 800);
$text-color: map_get(map_get($app-theme, 'foreground'), 'text');
$theme-foreground-text: map_get(map_get($app-theme, 'foreground'), 'text');
$theme-foreground-divider: mat.get-color-from-palette(map_get($app-theme, 'foreground'), divider);
$theme-foreground-secondary-text: map_get(map_get($app-theme, 'foreground'), 'secondary-text');

$rs_dark: #252525;
$rs_light: #f4f4f4;


// ======================== PROJECT CUSTOM VARIABLES ======================

$primary-color: $primary;
$main-bg-color: #ffffff;
$primary-color-hover: mat.get-color-from-palette($app-primary, default, 0.2);

//$primary: #084798;
$success: #7FB800;
$warning: #FFB400;
$danger: #d32f2f;
//$info: #d00696;
$info: #00c2d0;

$white:  #fff;
$black:  #111;
$red: #FF5B5A;
$red-light: mat.get-color-from-palette(mat.$red-palette, 50);
$orange: #f0ad4e;
$yellow: #FFC700;
$green: #8bc34b;
$blue: #1D8ACF;
$blue-light: #74cef8;
$teal: #00BCD4;
//$pink:   #ff5b77 !default;
//$purple: #613d7c !default;
$bluegray: #303030;
$bluegray-lighter: #7F8FA4;
$platinum-color: #dee3e8;

// Color names
$impressions-color: $yellow;
$ad-request-color: $bluegray;
$revenue-color: $teal;
$profit-color: $green;

// Status color
$status-color-active: $success;
$status-color-inactive: $bluegray-lighter;
$status-color-archived: black;
$status-color-goal-met: #FFC107;
$status-color-completed: #009688;
$status-color-delivering: #9C27B0;
$status-color-ready: #2196F3;

// Grayscale
$gray-dark: #292b2c !default;
$gray: #464a4c !default;
$gray-light: #636c72 !default;
$gray-lighter: #818181 !default;
$gray-lightest: #f7f7f9 !default;

$body-bg: $main-bg-color;
$app-background: $main-bg-color;
$bg-pattern: repeating-conic-gradient(#EEE 0% 25%, transparent 0% 50%) 50%/6px 6px;

$sidebar-background: $main-bg-color;
$sidebar-header-background: $main-bg-color;
$sidebar-header-color: $primary;
$sidebar-link-color: mat.get-color-from-palette(mat.$gray-palette, 800);
$sidebar-link-hover-background: rgba($primary, 0.05);
$sidebar-active-item-background: mat.get-color-from-palette(mat.$blue-palette, 50);

$table-row-hover: $gray-lightest;
$table-cell-padding-x: 6px;

$tree-node-background-color-hover: $main-bg-color;

$border-color: darken($main-bg-color, 6.5%);
$border-radius: 0px;
$border-radius-input: 5px; //Material outline inputs can't have more then 5px border radius so we need this on some elements for consistency

$box-shadow: 0 3px 6px #e1e2e3;
$box-shadow-z: 0 3px 0 0 $border-color, 0 3px 15px 0 $border-color;

$mobile-top-bar-height: 50px;
$sidebar-width: 230px;
$sidebar-width-collapsed: 60px;

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';

$font-family-base: 'Roboto', sans-serif;
$font-weight-base: 400;
$headings-font-weight: 600;
$font-weight-bolder: 600;
$base-font-size: 16px;
$close-font-size: 12;

// Custom scrollbar

$scrollbar-width: 8px;
$scrollbar-bg: darken($app-background, 5%);
$scrollbar-fg: #AAA;

$active-text: #137333;
$active-bg: #b4fbc8;

$active-text-ai: #137373;
$active-bg-ai: #b4fbe9;

$inactive-text: #c5221f;
$inactive-bg: #febcb6;

$inactive-text-orange: #c58b1f;
$inactive-bg-orange: #feebb6;

$archived-text: #5f6367;
$archived-bg: #dbdcdd;

// ======================== BOOTSTRAP SCSS ======================

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$grid-gutter-width: 24px;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1600px,
  uhd-up: 1920px
);

$container-max-widths: (
  xxl: 1280px
);

// ======================== MIXINS ======================

@import 'mixins';
