@use "sass:math";

.mat-checkbox {

  .mat-checkbox-layout {

    .mat-table & {
      margin-bottom: 0;
    }
  }


  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: math.div($border-radius, 3)
  }
}
